import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { createEffect } from 'ngxtension/create-effect';
import { EMPTY, exhaustMap, Observable, of, tap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ViewRoles } from '../../app.menu';
import { RoleEnum } from '../../features/admin/features/users/models/role-enum-model';
import { User } from '../../features/admin/features/users/models/user.model';
import { UserRole } from '../../features/admin/features/users/models/user-roles.model';
import { API_URL } from '../api-url.token';
import { Sequences } from '../sequences';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private router = inject(Router);
  private apiUrl = inject(API_URL);
  private http = inject(HttpClient);
  private toast = inject(ToastrService);

  loggingIn = signal(false);
  signingUp = signal(false);

  user = signal<User | null>(this.getUserFromLocalStorage());

  get userIsLoggedIn(): boolean {
    return this.getToken() != null;
  }

  get userIsAdmin(): boolean {
    if (!this.userIsLoggedIn) return false;
    return this.user()!.isAdmin;
  }

  userHasRole(entityId: number, requiredRole: RoleEnum): boolean {
    if (!this.userIsLoggedIn) return false;
    return this.hasRole(this.user()!.roles, entityId, requiredRole);
  }

  private hasRole(roles: UserRole[] | undefined, entityId: number, requiredRole: RoleEnum): boolean {
    if (!roles) return false;
    // @ts-ignore
    return roles.some(userRole => userRole.entityId === entityId && (userRole.role & requiredRole) === requiredRole) ?? false;
  }

  get userViewRoles(): ViewRoles[] {
    if (!this.userIsLoggedIn) return [ViewRoles.NotLoggedIn];

    let roles: ViewRoles[] = [];

    if (this.user()!.isAdmin) {
      roles.push(ViewRoles.Admin);
    }

    this.user()!.roles!.map(role => {
      if (role.entityId === 0) {
        if (role.role === RoleEnum.Admin) {
          if (!roles.includes(ViewRoles.Admin)) {
            roles.push(ViewRoles.Admin);
          }
        }
        if (role.role === RoleEnum.Manage) {
          if (!roles.includes(ViewRoles.Manage)) {
            roles.push(ViewRoles.Manage);
          }
        }
        if (role.role === RoleEnum.SuperUser) {
          if (!roles.includes(ViewRoles.SuperUser)) {
            roles.push(ViewRoles.SuperUser);
          }
        }
      } else if (role.entityId >= Sequences.AffiliateStartId && role.entityId < Sequences.AffiliateStartId + Sequences.SequenceSize) {
        if (!roles.includes(ViewRoles.Affiliate)) {
          roles.push(ViewRoles.Affiliate);
        }
      } else if (role.entityId >= Sequences.BrokerStartId && role.entityId < Sequences.BrokerStartId + Sequences.SequenceSize) {
        if (!roles.includes(ViewRoles.Broker)) {
          roles.push(ViewRoles.Broker);
        }
      } else if (role.entityId >= Sequences.LenderStartId && role.entityId < Sequences.LenderStartId + Sequences.SequenceSize) {
        if (!roles.includes(ViewRoles.Lender)) {
          roles.push(ViewRoles.Lender);
        }
      }
    });

    return roles;
  }

  getToken(): string | null {
    var token = localStorage.getItem('jwt_token');
    var expires = localStorage.getItem('jwt_expires');
    var user = localStorage.getItem('user');

    if (token == null || expires == null || user == null) {
      console.log('getToken() == null');
      this.cleanup();
      return null;
    }

    var expiresDate = new Date(expires);
    var now = new Date();
    if (expiresDate <= now) {
      console.log('getToken() == expired');
      this.cleanup();
      return null;
    }

    return token;
  }

  signUp = createEffect<{ payload: Partial<User>; form: FormGroup }>(
    exhaustMap(({ payload, form }) => {
      this.signingUp.set(true);
      return this.http.post<any>(`${this.apiUrl}/api/register`, payload).pipe(
        tap(res => {
          this.toast.success('Successfully registered!');
          this.signingUp.set(false);
          this.router.navigate(['/auth/login']);
        }),
        catchError(err => {
          Object.values(err.error.errors).forEach((s: any) => this.toast.error(s));
          this.signingUp.set(false);
          form.reset();
          return EMPTY;
        })
      );
    })
  );

  // Sign-in
  signIn = createEffect<{ payload: { email: string; password: string }; form: FormGroup }>(
    exhaustMap(({ payload, form }) => {
      this.loggingIn.set(true);
      return this.http.post<any>(`${this.apiUrl}/api/authenticate`, payload).pipe(
        tap(res => {
          localStorage.setItem('jwt_token', res.result.token);
          localStorage.setItem('jwt_expires', res.result.expires);
          // TODO: Implement refresh token
          localStorage.setItem('jwt_refresh', res.result.refresh);

          // Set the user object and save it to local storage.
          this.user.set(res.result.user);

          localStorage.setItem('user', JSON.stringify(this.user()));

          this.toast.success('Successfully logged in!');

          this.router.navigate(['dashboard']);
          this.loggingIn.set(false);
        }),
        catchError(err => {
          this.toast.error('Error logging in!');
          form.reset();
          console.error(err);
          this.loggingIn.set(false);
          return of(null);
        })
      );
    })
  );

  doLogout() {
    this.cleanup();
    this.router.navigate(['/auth/login']);
  }

  // User profile
  getUserProfile(id: any): Observable<any> {
    console.log('getUserProfile() id =', id);

    return this.http.get(`${this.apiUrl}/api/users/${id}`).pipe(
      map(res => res || {}),
      // TODO: Change to use the Angular built-in..
      catchError(this.handleError)
    );
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log('handleError() error =', error);
    console.log('handleError() error.error =', error.error);

    if (error.status === 0) {
      return throwError(() => 'Could not connect to the server. Please check your internet connection.');
    }

    let msg = '';

    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    this.toast.error(msg);

    return throwError(() => msg);
  }

  private cleanup() {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('jwt_expires');
    localStorage.removeItem('jwt_refresh');
    localStorage.removeItem('user');
  }

  private getUserFromLocalStorage(): User | null {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;
  }
}
